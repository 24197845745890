import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Container } from "@material-ui/core"
import { navigate } from "gatsby"
import { Button, Form, Popup } from "devextreme-react"
import { Item } from "devextreme-react/form"

import "devextreme/dist/css/dx.common.css"
import "devextreme/dist/css/dx.light.css"

import Layout from "../../components/layout"
import PageTitle from "../../components/page-title"
import SEO from "../../components/seo"
import shopify from "../../images/services/shopify.svg"
import { sendLandingPage } from "../../components/operations/shopify"

const RequestDemoW = styled.div``
const PopupContentW = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const LandingPage = ({ location }) => {
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [message, setMessage] = useState("")
  const [showRetryButton, setShowRetryButton] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(false)
  const [result, setResult] = useState(null)
  const retry = useRef(null)

  const sendInfo = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get("code")
    const hmac = searchParams.get("hmac")
    const shop = searchParams.get("shop")
    const state = searchParams.get("state")
    const timestamp = searchParams.get("timestamp")
    if (!hmac) {
      navigate("/")
      return
    }
    try {
      setMessage("Connecting to Shopify ...")
      setShowRetryButton(false)
      retry.current++
      const result = await sendLandingPage({
        code,
        hmac,
        shop,
        state,
        timestamp,
      })
      setResult(result.data)
      setMessage("Congratulations: you connected to Shopify successfully.")
      setPopupVisibility(false)
      setShowRetryButton(false)
      setConnectionStatus(true)
    } catch (error) {
      setPopupVisibility(true)
      if (retry.current > 3) {
        setShowRetryButton(false)
        setMessage(
          `Something went wrong! please contact us. We will call soon.`
        )
      } else {
        setMessage(`Connecting to Shopify failed: ${error}.`)
        setShowRetryButton(true)
      }
    }
  }, [location.search])

  useEffect(() => {
    sendInfo()
  }, [sendInfo])

  const popupHidingHandler = useCallback(() => {
    connectionStatus && setPopupVisibility(false)
  }, [connectionStatus])

  const contactUsClickHandler = useCallback(() => {
    navigate("/contact-us")
  }, [])

  if (typeof window === `undefined`) {
    return null
  }

  return (
    <Layout>
      <SEO title="Shopify landing page" />
      <PageTitle
        title="Shopify"
        subTitle="sales@datamap.tools"
        icon={shopify}
      />
      <RequestDemoW>
        <Container>
          <Popup
            visible={popupVisibility}
            onHiding={popupHidingHandler}
            dragEnabled={false}
            closeOnOutsideClick={connectionStatus}
            showCloseButton={connectionStatus}
            showTitle={true}
            title="Connect to Shopify"
            width={400}
            height={200}
          >
            <PopupContentW>
              <div>{message}</div>
              <Button
                text="Try Again"
                visible={showRetryButton}
                onClick={sendInfo}
              />
              <Button
                text="contactUs"
                visible={retry.current > 3}
                onClick={contactUsClickHandler}
              />
            </PopupContentW>
          </Popup>
          <Form
            formData={result?.shop}
            colCount={2}
            readOnly={true}
            visible={connectionStatus}
          >
            <Item dataField="name" />
            <Item dataField="domain" />
            <Item dataField="province" />
            <Item dataField="country_name" />
            <Item dataField="city" />
            <Item dataField="currency" />
            <Item dataField="timezone" />
            <Item dataField="shop_owner" />
          </Form>
        </Container>
      </RequestDemoW>
    </Layout>
  )
}

export default LandingPage
